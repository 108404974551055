/* call cp_browserAlert.init(your_language, check_old_ie); after script tag with cp_browser_alert_v2.js
your_language - 'rus' or 'eng'
check_old_ie - 'iex'
*/
var cp_browserAlert = cp_browserAlert || {};

(function (obj) {
    obj.options = {
        cp_styles: '.cp-browser-alert{box-sizing:border-box;width:100%;z-index:1100;padding:10px;padding-right:20px;background-color:#e58d37;color:#fff;position:fixed;top:0;left:0;font-family:sans-serif;}.cp-browser-alert a{color:#fff;font-weight:700;text-decoration:underline}.cp-browser-alert a:hover{text-decoration:none}.cp-browser-alert .cp-browser-alert__text{float:left}.cp-browser-alert .cp-browser-alert__close{position:absolute;right:25px;top:10px}.cp-browser-alert .cp-browser-alert__close:hover{cursor:pointer}.cp-browser-alert .browser-alert__clearfix{clear:both}',
        cp_message_rus: ' Вы используете устаревший браузер. Для корректного отображения данной страницы рекомендуется использовать более <a href="https://www.google.com/search?q=скачать+бразуер" target="_blank">современный браузер</a>',
        cp_message_eng: ' You are using an outdated browser. For a better experience using this site, please upgrade to a <a href="https://www.google.com/search?q=download+browser" target="_blank">modern web browser',
        cp_detector: '<!--[if lt IE 10]><div class="cp-isoldie" style="display: none;"></div><![endif]-->'
    }

    /**
     * detect IE
     * returns true or false
     */
    var detectIE = function (browser) {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0 && browser === 'iex') {
            // IE 10 or older
            return true;
        };

        var trident = ua.indexOf('Trident/');
        if (trident > 0 && browser === 'iex') {
            // IE 11
            return true;
        };

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+)
            return false;
        };

        // other browser
        return false;
    }

    var renderElements = function (lang) {
        var ba_banner = document.createElement('div');
        ba_banner.className = 'cp-browser-alert';
        var ba_wrapper = document.createElement('div');
        ba_wrapper.className = 'cp-browser-alert-wrapper';
        var ba_text = document.createElement('div');
        ba_text.className = 'cp-browser-alert__text';
        var ba_close = document.createElement('div');
        ba_close.className = 'cp-browser-alert__close';
        var ba_clearfix = document.createElement('div');
        ba_clearfix.className = 'cp-browser-alert__clearfix';
        var cp_stylesTag = document.createElement('style');
        document.body.appendChild(cp_stylesTag);
        cp_stylesTag.textContent = obj.options.cp_styles;
        var cp_txt = '';

        if (lang !== undefined) {
            if (lang === 'rus') {
                cp_txt = obj.options.cp_message_rus;
            }
            if (lang === 'eng') {
                cp_txt = obj.options.cp_message_eng;
            }
        } else {
            cp_txt = obj.options.cp_message_eng
        }

        ba_text.innerHTML = cp_txt;
        ba_close.textContent = 'X';
        ba_close.setAttribute('onclick', 'javascript:this.parentNode.parentNode.style.display="none"; return false;');

        document.body.appendChild(ba_banner);
        ba_banner.appendChild(ba_wrapper);
        ba_wrapper.appendChild(ba_text);
        ba_wrapper.appendChild(ba_close);
        ba_wrapper.appendChild(ba_clearfix);
    }

    obj.init = function (lang, browser) {
        /* Check if IE */
        var isIE = false;
        isIE = detectIE(browser);
        if (isIE) {
            //console.log(isIE);
            //console.log(window.navigator.userAgent);
            renderElements(lang);
        }
    }
})(cp_browserAlert);